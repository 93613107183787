<template>
  <permissions-layout
    page-class="you-tube-tutorials"
    icon="play"
    pageTitle="Youtube Tutorials"
  >
    <p class="info">Tutorials to get you started!</p>
    <button class="btn light with-shadow" @click="setStep(3)">
      Clebex Youtube channel
    </button>
  </permissions-layout>
</template>

<script>
import permissionsMixin from "@/services/mixins/permissions/login-permissions-mixin";
export default {
  name: "YouTubeTutorials",
  mixins: [permissionsMixin]
};
</script>
