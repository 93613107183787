<template>
  <div class="page-title-container">
    <!-- TODO srediti ove ikonice kasnije -->
    <svg-icon v-if="icon" :icon="icon"></svg-icon>
    <svg-icon v-else icon="user"></svg-icon>
    <h1 class="page-title">{{ pageTitle }}</h1>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    pageTitle: {
      required: true,
      type: String
    },
    icon: {
      required: true
    }
  }
};
</script>
