<template>
  <camera-access v-if="step === 1"></camera-access>
  <you-tube-tutorials v-if="step === 2"></you-tube-tutorials>
  <clebex-manual v-if="step === 3"></clebex-manual>
</template>

<script>
import { mapState } from "vuex";
import CameraAccess from "@/components/permissions/CameraAccess";
import YouTubeTutorials from "@/components/permissions/YouTubeTutorials";
import ClebexManual from "@/components/permissions/ClebexManual";

export default {
  name: "Permissions",
  computed: {
    ...mapState("permissions", ["step"])
  },
  components: {
    CameraAccess,
    YouTubeTutorials,
    ClebexManual
  }
};
</script>
