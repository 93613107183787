<template>
  <main class="permissions-layout" :class="{ [pageClass]: pageClass }">
    <large-logo></large-logo>
    <page-title :pageTitle="pageTitle" :icon="icon">
      <slot />
      <section class="steps-indicator-wrapper">
        <button class="btn tiny text">SKIP</button>
        <steps-indicator :steps="3" :current-step="step"></steps-indicator>
      </section>
    </page-title>
  </main>
</template>

<script>
import LargeLogo from "@/components/global/LargeLogo";
import PageTitle from "@/components/global/PageTitle";
import StepsIndicator from "@/components/permissions/StepsIndicator";
import { mapState } from "vuex";
export default {
  name: "PermissionsLayout",
  components: {
    LargeLogo,
    PageTitle,
    StepsIndicator
  },
  computed: {
    ...mapState("permissions", ["step"])
  },
  props: {
    pageClass: {
      required: false,
      type: String
    },
    pageTitle: {
      required: true,
      type: String
    },
    icon: {
      required: true
    },
    info: {
      required: false,
      type: String
    }
  }
};
</script>
