<template>
  <permissions-layout
    page-class="camera-access"
    icon="camera"
    pageTitle="Camera Access"
  >
    <p class="info">
      While using this application, you will need to use the camera for
      declaration purposes.
    </p>
    <button class="btn light with-shadow" @click="setStep(2)">
      Allow camera access
    </button>
  </permissions-layout>
</template>

<script>
import permissionsMixin from "@/services/mixins/permissions/login-permissions-mixin";
export default {
  name: "CameraAccess",
  mixins: [permissionsMixin]
};
</script>
