<template>
  <ul class="steps-indicator" v-if="steps">
    <li
      v-for="n in steps"
      class="step"
      :class="{ active: n === currentStep }"
      :key="n"
    ></li>
  </ul>
</template>

<script>
export default {
  name: "StepsIndicator",
  props: {
    steps: {
      type: Number
    },
    currentStep: {
      type: Number
    }
  }
};
</script>
