<template>
  <permissions-layout
    page-class="camera-access"
    icon="documents"
    pageTitle="Clebex Manual"
  >
    <p class="info">
      Manual for the Clebex Solutions
    </p>
    <router-link :to="{ name: 'r_plan' }" class="btn light with-shadow">
      Checkout manual
    </router-link>
  </permissions-layout>
</template>

<script>
import permissionsMixin from "@/services/mixins/permissions/login-permissions-mixin";
export default {
  name: "ClebexManual",
  mixins: [permissionsMixin]
};
</script>
