import PermissionsLayout from "@/layouts/PermissionsLayout.vue";
import { mapActions } from "vuex";

export default {
  components: {
    PermissionsLayout
  },
  methods: {
    ...mapActions("loginPermissions", ["setStep"])
  }
};
